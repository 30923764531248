<template>
  <div>
    <v-app-bar class="mt-2" color="rgba(0, 0, 0, 0)" flat dense>
      <v-toolbar-title>
        <span class="text-h5"> 営業マージン集計表</span>
      </v-toolbar-title>

      <v-spacer />

      <v-sheet class="ml-2" width="200">
        <v-select
          v-model="filter.branchs"
          label="支店"
          :items="branchs"
          @change="onBranchChanged"
          item-value="code"
          item-text="name"
          return-object
          multiple
          clearable
        />
      </v-sheet>
      <v-sheet class="ml-2" width="200">
        <v-select
          v-model="filter.teams"
          label="チーム"
          :items="teams"
          @change="onTeamChanged"
          item-value="code"
          item-text="name"
          return-object
          multiple
          clearable
        />
      </v-sheet>
      <v-sheet class="ml-2" width="200">
        <v-select
          v-model="filter.employees"
          label="社員"
          :items="employees"
          @change="onEmployeeChanged"
          item-value="code"
          item-text="name"
          return-object
          multiple
          clearable
        />
      </v-sheet>

      <v-spacer />

      <!-- <v-sheet width="100"> -->
      <v-select v-model="term" label="期" :items="terms" @change="onTermChanged" :full-width="false" />
      <!-- </v-sheet> -->
    </v-app-bar>

    <XLoader :loading="initializing">
      <template v-slot="{ loaded }">
        <div v-if="loaded">
          <v-card outlined>
            <v-card-title>
              <span class="text-caption"> 表示 : </span>
              <v-chip-group class="ml-2" active-class="blue lighten-4" v-model="visibledColumns" column multiple>
                <v-chip v-for="(comun, index) of columnNames" small :key="`column${index}`">{{ comun }}</v-chip>
              </v-chip-group>
              <v-spacer />
              <v-speed-dial v-model="fab" direction="left" transition="slide-y-reverse-transition" left bottom>
                <template v-slot:activator>
                  <v-btn class="mt-4 mr-4" v-model="fab" small outlined>
                    <v-icon v-if="fab"> mdi-close </v-icon>
                    <v-icon v-else> mdi-playlist-plus </v-icon>
                  </v-btn>
                </template>
                <template
                  v-for="(merginType, index) of [
                    MerginTypes.Sukesan,
                    MerginTypes.Rental,
                    MerginTypes.Yadoda,
                    MerginTypes.SP,
                    MerginTypes.Sale,
                  ]"
                >
                  <v-btn dark small color="green" outlined @click="onShowCreator(merginType)" :key="`merginType${index}`">
                    {{ merginType | merginType }}
                  </v-btn>
                </template>
              </v-speed-dial>
            </v-card-title>
            <v-card-text>
              <v-tabs v-model="tab" @change="onMonthChanged">
                <v-tab> 全期間</v-tab>
                <v-tab v-for="month of 12" :key="`month${month}`">
                  {{
                    $moment(dateStart)
                      .add(month - 1, 'months')
                      .format('M')
                  }}月
                </v-tab>
              </v-tabs>
              <!-- {{ rankingDatas }} -->

              <v-simple-table class="table table-responsive table-striped table-bordered" dense>
                <template v-slot:default>
                  <thead>
                    <tr class="deep-purple lighten-4">
                      <th v-show="isColumnVisibled(0)" scope="col">{{ columnNames[0] }}</th>
                      <th v-show="isColumnVisibled(1)" scope="col">{{ columnNames[1] }}</th>
                      <th v-show="isColumnVisibled(2)" scope="col">{{ columnNames[2] }}</th>
                      <th v-show="isColumnVisibled(3)" scope="col">{{ columnNames[3] }}</th>
                      <th v-show="isColumnVisibled(4)" scope="col">{{ columnNames[4] }}</th>
                      <th v-show="isColumnVisibled(5)" scope="col">{{ columnNames[5] }}</th>
                      <th v-show="isColumnVisibled(6)" scope="col">{{ columnNames[6] }}</th>
                      <th v-show="isColumnVisibled(7)" scope="col">{{ columnNames[7] }}</th>
                      <th v-show="isColumnVisibled(8)" scope="col">{{ columnNames[8] }}</th>
                      <th v-show="isColumnVisibled(9)" scope="col">{{ columnNames[9] }}</th>
                      <th v-show="isColumnVisibled(10)" scope="col">{{ columnNames[10] }}</th>
                      <th v-show="isColumnVisibled(11)" scope="col">{{ columnNames[11] }}</th>
                      <th v-show="isColumnVisibled(12)" scope="col">{{ columnNames[12] }}</th>
                      <th v-show="isColumnVisibled(13)" scope="col">{{ columnNames[13] }}</th>
                      <th v-show="isColumnVisibled(14)" scope="col">{{ columnNames[14] }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(data, index) of datas">
                      <!--デモがかかわるマージン情報-->
                      <template v-if="data.demo != null">
                        <tr :key="`row1${index}`">
                          <td v-show="isColumnVisibled(0)" rowspan="2">{{ data.date | date }}</td>
                          <td v-show="isColumnVisibled(1)" rowspan="2">{{ data.branch.name }}</td>
                          <td v-show="isColumnVisibled(2)" rowspan="2">{{ data.team.name }}</td>
                          <td v-show="isColumnVisibled(3)" rowspan="2">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span class="d-inline-block text-truncate" style="max-width: 250px" v-bind="attrs" v-on="on">
                                  {{ data.demo.facility.name }}
                                </span>
                              </template>
                              {{ data.demo.facility.name }}
                            </v-tooltip>
                          </td>
                          <td v-show="isColumnVisibled(4)" rowspan="2">{{ data.demo.merginAmount | priceJP }}</td>
                          <td v-show="isColumnVisibled(5)" rowspan="2">{{ data.demo.residualBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(6)" rowspan="2">{{ data.demo.underwritingBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(7)" rowspan="2">{{ data.demo.complimentaryBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(8)" rowspan="2">{{ data.demo.contractType | demoContractType }}</td>
                          <td v-show="isColumnVisibled(9)" rowspan="2">
                            {{
                              getAmountBase(
                                data.demo.merginAmount,
                                data.demo.residualBonds,
                                data.demo.underwritingBonds,
                                data.demo.complimentaryBonds
                              ) | priceJP
                            }}
                          </td>
                          <template v-if="canDispDemointer(data)">
                            <td v-show="isColumnVisibled(10)">
                              <v-chip :color="getDemoMerginTypeColor(DemoMerginTypes.Demo)" x-small>デモ</v-chip>
                              {{ data.demo.demointer.name }}
                            </td>
                            <td v-show="isColumnVisibled(11)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Demo).rate != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Demo).rate | percent }}%
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>{{ data.demo.prospectMerginDemoRate | percent }}%</v-chip>
                              </template>
                            </td>

                            <td v-show="isColumnVisibled(12)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Demo).adjustment != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Demo).adjustment | priceJP }}
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>-</v-chip>
                              </template>
                            </td>
                            <td v-show="isColumnVisibled(13)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Demo).amount != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Demo).amount | priceJP }}
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>
                                  {{
                                    getAmount(
                                      data.demo.merginAmount,
                                      data.demo.residualBonds,
                                      data.demo.underwritingBonds,
                                      data.demo.complimentaryBonds,
                                      data.demo.prospectMerginDemoRate,
                                      null
                                    ) | priceJP
                                  }}
                                </v-chip>
                              </template>
                            </td>

                            <td v-show="isColumnVisibled(14)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Demo).inputedDate != null">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      color="success"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        onShowDemoEditor(
                                          data.demo.code,
                                          DemoMerginTypes.Demo,
                                          data.demo.merginAmount,
                                          data.demo.residualBonds,
                                          data.demo.underwritingBonds,
                                          data.demo.complimentaryBonds,
                                          data.demo.prospectMerginDemoRate
                                        )
                                      "
                                    >
                                      業務承認済
                                    </v-chip>
                                  </template>
                                  <span>{{ getMergin(data.demo.code, DemoMerginTypes.Demo).inputedDate | date }}</span>
                                </v-tooltip>
                              </template>
                              <template v-else>
                                <v-chip
                                  color="warning"
                                  small
                                  outlined
                                  @click="
                                    onShowDemoEditor(
                                      data.demo.code,
                                      DemoMerginTypes.Demo,
                                      data.demo.merginAmount,
                                      data.demo.residualBonds,
                                      data.demo.underwritingBonds,
                                      data.demo.complimentaryBonds,
                                      data.demo.prospectMerginDemoRate
                                    )
                                  "
                                >
                                  <v-icon v-if="!getMergin(data.demo.code, DemoMerginTypes.Demo).isEmpty" v-text="`mdi-pen`" small />
                                  業務未承認
                                </v-chip>
                              </template>
                            </td>
                          </template>
                        </tr>

                        <tr :key="`row2${index}`">
                          <template v-if="canDispApointer(data)">
                            <td v-show="isColumnVisibled(10)">
                              <v-chip :color="getDemoMerginTypeColor(DemoMerginTypes.Apo)" x-small> アポ </v-chip>

                              <!-- アポインター情報情報がアポとデモで異なっている不正なデータ -->
                              <v-chip v-if="data.demo.invalidApointer" color="warning" x-small> 不正 </v-chip>

                              <span v-else-if="data.demo.apo != null && data.demo.apo.apointer != null">
                                {{ data.demo.apo.apointer.name }}
                              </span>
                              <span v-else-if="data.demo.apointer != null">
                                {{ data.demo.apointer.name }}
                              </span>
                              <v-chip v-else-if="data.demo.apo != null && data.demo.apo.branch != null" x-small>
                                {{ data.demo.apo.branch.name }}
                              </v-chip>
                              <v-chip v-else color="warning" x-small> ?? </v-chip>
                            </td>
                            <td v-show="isColumnVisibled(11)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Apo).rate != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Apo).rate | percent }}%
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>{{ data.demo.prospectMerginApoRate | percent }}%</v-chip>
                              </template>
                            </td>

                            <td v-show="isColumnVisibled(12)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Apo).adjustment != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Demo).adjustment | priceJP }}
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>-</v-chip>
                              </template>
                            </td>
                            <td v-show="isColumnVisibled(13)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Apo).amount != null">
                                <v-chip :color="getModifiedColor(true)" small>
                                  {{ getMergin(data.demo.code, DemoMerginTypes.Apo).amount | priceJP }}
                                </v-chip>
                              </template>
                              <template v-else>
                                <v-chip color="transparent" small>
                                  {{
                                    getAmount(
                                      data.demo.merginAmount,
                                      data.demo.residualBonds,
                                      data.demo.underwritingBonds,
                                      data.demo.complimentaryBonds,
                                      data.demo.prospectMerginApoRate,
                                      null
                                    ) | priceJP
                                  }}</v-chip
                                >
                              </template>
                            </td>

                            <td v-show="isColumnVisibled(14)">
                              <template v-if="getMergin(data.demo.code, DemoMerginTypes.Apo).inputedDate != null">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      color="success"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        onShowDemoEditor(
                                          data.demo.code,
                                          DemoMerginTypes.Apo,
                                          data.demo.merginAmount,
                                          data.demo.residualBonds,
                                          data.demo.underwritingBonds,
                                          data.demo.complimentaryBonds,
                                          data.demo.prospectMerginApoRate
                                        )
                                      "
                                    >
                                      業務承認済
                                    </v-chip>
                                  </template>
                                  <span>{{ getMergin(data.demo.code, DemoMerginTypes.Apo).inputedDate | date }}</span>
                                </v-tooltip>
                              </template>
                              <template v-else>
                                <v-chip
                                  color="warning"
                                  small
                                  outlined
                                  @click="
                                    onShowDemoEditor(
                                      data.demo.code,
                                      DemoMerginTypes.Apo,
                                      data.demo.merginAmount,
                                      data.demo.residualBonds,
                                      data.demo.underwritingBonds,
                                      data.demo.complimentaryBonds,
                                      data.demo.prospectMerginApoRate
                                    )
                                  "
                                >
                                  <v-icon v-if="!getMergin(data.demo.code, DemoMerginTypes.Apo).isEmpty" v-text="`mdi-pen`" small />

                                  業務未承認
                                </v-chip>
                              </template>
                            </td>
                          </template>
                        </tr>
                      </template>

                      <!-- 直接入力  -->
                      <template v-else>
                        <tr :key="`row3${index}`">
                          <td v-show="isColumnVisibled(0)">{{ data.date | date }}</td>
                          <td v-show="isColumnVisibled(1)">{{ data.branch.name }}</td>
                          <td v-show="isColumnVisibled(2)">{{ data.team.name }}</td>
                          <td v-show="isColumnVisibled(3)">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span class="d-inline-block text-truncate" style="max-width: 250px" v-bind="attrs" v-on="on">
                                  {{ data.mergin.facility.name }}
                                </span>
                              </template>
                              {{ data.mergin.facility.name }}
                            </v-tooltip>
                          </td>
                          <td v-show="isColumnVisibled(4)">{{ data.mergin.sales | priceJP }}</td>
                          <td v-show="isColumnVisibled(5)">{{ data.mergin.residualBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(6)">{{ data.mergin.underwritingBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(7)">{{ data.mergin.complimentaryBonds | priceJP }}</td>
                          <td v-show="isColumnVisibled(8)">{{ data.mergin.demoContractType | demoContractType }}</td>
                          <td v-show="isColumnVisibled(9)">{{ data.mergin.resultAmount | priceJP }}</td>

                          <td v-show="isColumnVisibled(10)">
                            <v-chip :color="getMerginTypeColor(data.mergin.merginType)" x-small>
                              {{ data.mergin.merginType | merginType }}
                            </v-chip>
                            <v-chip :color="getDemoMerginTypeColor(data.mergin.demoMerginType)" x-small>
                              {{ getDemoMerginTypeLabel(data.mergin.demoMerginType) }}
                            </v-chip>
                            {{ data.employee.map((a) => a.name) | implode }}
                          </td>
                          <td v-show="isColumnVisibled(11)">{{ data.mergin.rate | percent }}%</td>
                          <td v-show="isColumnVisibled(12)">{{ data.mergin.adjustment | priceJP }}</td>
                          <td v-show="isColumnVisibled(13)">{{ data.mergin.amount | priceJP }}</td>

                          <td v-show="isColumnVisibled(14)">
                            <template v-if="data.mergin.inputedDate != null">
                              <v-chip color="success" small @click="onShowEditor(data.mergin.code)">
                                <v-icon v-text="`mdi-check`" small left />
                                業務承認済
                              </v-chip>
                            </template>
                            <template v-else>
                              <v-chip color="warning" small outlined @click="onShowEditor(data.mergin.code)"> 業務未承認 </v-chip>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </XLoader>

    <v-bottom-sheet scrollable v-model="creator.show" persistent width="700" offset-y>
      <v-card>
        <v-card-title>
          <v-btn text color="warning" @click="creator.show = false"> 閉じる </v-btn>
          <v-spacer />
          手動マージン登録
          <v-spacer />
          <v-btn color="primary" :disabled="!canCommitCreator()" @click="onCommitCreator(new Date())">業務承認 </v-btn>
        </v-card-title>
        <v-card-text>
          <XLoader :loading="creator.loading">
            <template v-slot="{ loaded }">
              <div v-if="loaded">
                <v-select
                  v-model="creator.merginType"
                  label="種別*"
                  :items="[
                    { text: $options.filters.merginType(MerginTypes.Sukesan), value: MerginTypes.Sukesan },
                    { text: $options.filters.merginType(MerginTypes.Rental), value: MerginTypes.Rental },
                    { text: $options.filters.merginType(MerginTypes.Yadoda), value: MerginTypes.Yadoda },
                    { text: $options.filters.merginType(MerginTypes.SP), value: MerginTypes.SP },
                    { text: $options.filters.merginType(MerginTypes.Sale), value: MerginTypes.Sale },
                  ]"
                />

                <v-row class="mb-4">
                  <v-col cols="4"> 対象日* </v-col>
                  <v-col cols="8">
                    <DateTime label="対象日" v-model="creator.date" hideTime>
                      <template v-slot="{ click }">
                        <v-btn outlined v-bind="click.attrs" v-on="click.on"> {{ creator.date | date }} </v-btn>
                      </template>
                    </DateTime>
                  </v-col>
                </v-row>

                <template v-if="creator.merginType == MerginTypes.Sale">
                  <v-card class="my-2 mb-4" outlined>
                    <v-card-title>営業情報</v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="3">
                          <validation-provider v-slot="{ errors }" name="アポ・デモ" rules="required">
                            <v-select
                              v-model="creator.demoMerginType"
                              :items="[
                                { text: 'アポ', value: DemoMerginTypes.Apo },
                                { text: 'デモ', value: DemoMerginTypes.Demo },
                              ]"
                              label="*アポ・デモ"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                              @input="onChangeCreatorRate"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="3">
                          <validation-provider v-slot="{ errors }" name="新規・リプレイス" rules="required">
                            <v-select
                              v-model="creator.demoContractType"
                              :items="[
                                { text: '新規', value: 1 },
                                { text: 'リプレイス', value: 1 << 1 },
                              ]"
                              label="*新規・新規・リプレイス"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                              @input="onChangeCreatorRate"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="3">
                          受注日
                          <DateTime label="受注日" v-model="creator.saleDate" hideTime>
                            <template v-slot="{ click }">
                              <v-btn outlined v-bind="click.attrs" v-on="click.on"> {{ creator.saleDate | date }} </v-btn>
                            </template>
                          </DateTime>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field type="number" v-model.number="creator.sales" label="受注金額*" clearable outlined dense>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="creator.resultAmount"
                            label="売上金額*"
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="creator.residualBonds"
                            label="残債"
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="creator.underwritingBonds"
                            label="他社買取"
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="creator.complimentaryBonds"
                            label="サービス"
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            v-model.number="creator.rate"
                            label="マージン率"
                            hint="例: 1% = 0.01と入力"
                            persistent-hint
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            v-model.number="creator.adjustment"
                            label="調整額"
                            clearable
                            outlined
                            dense
                            @input="onChangeCreator"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>

                <v-text-field type="number" v-model.number="creator.amount" label="報酬金額*" clearable outlined dense> </v-text-field>

                <v-select
                  v-model="creator.branch"
                  label="支店*"
                  :items="creator.branchs"
                  @change="creator.teams = creator.branch.teams"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-select
                  v-model="creator.team"
                  label="チーム*"
                  :items="creator.teams"
                  @change="creator.employees = creator.team.employees"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-select
                  v-model="creator.employee"
                  label="社員*"
                  :items="creator.employees"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-row>
                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" name="電話番号" rules="required|max:50">
                      <v-text-field
                        v-model="creator.facilityTel"
                        label="*電話番号"
                        :rules="[(v) => !!v || '電話番号 は必須です']"
                        hint="ハイフン抜きで入力してください"
                        counter
                        :maxlength="50"
                        :error-messages="errors"
                        clearable
                        required
                        dense
                        @input="onFacilityTelInput"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="8">
                    <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
                      <v-combobox
                        v-model="creator.facility"
                        :items="creator.facilities"
                        item-text="name"
                        item-value="code"
                        label="*顧客名*"
                        :rules="[(v) => !!v || '顧客名 は必須です']"
                        :hint="`検索結果:${creator.facilities.length}件`"
                        :error-messages="errors"
                        persistent-hint
                        return-object
                        clearable
                        required
                        outlined
                        dense
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-textarea class="mt-2" v-model="creator.memo" label="備考" clearable outlined dense> </v-textarea>
              </div>
            </template>
          </XLoader>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="success" :disabled="!canCommitCreator()" @click="onCommitCreator(null)"> 仮登録 </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet scrollable v-model="editor.show" persistent width="700" offset-y>
      <v-card>
        <v-card-title>
          <v-btn text color="warning" @click="editor.show = false"> 閉じる </v-btn>
          <v-spacer />
          <v-btn color="primary" :disabled="!canCommitEditor()" @click="onCommitEditor(new Date())">業務承認 </v-btn>
        </v-card-title>
        <v-card-text>
          <XLoader :loading="editor.loading">
            <template v-slot="{ loaded }">
              <div v-if="loaded">
                <v-select
                  v-model="editor.merginType"
                  label="種別*"
                  :items="[
                    { text: $options.filters.merginType(MerginTypes.Sukesan), value: MerginTypes.Sukesan },
                    { text: $options.filters.merginType(MerginTypes.Rental), value: MerginTypes.Rental },
                    { text: $options.filters.merginType(MerginTypes.Yadoda), value: MerginTypes.Yadoda },
                    { text: $options.filters.merginType(MerginTypes.SP), value: MerginTypes.SP },
                    { text: $options.filters.merginType(MerginTypes.Sale), value: MerginTypes.Sale },
                  ]"
                />

                <v-row class="mb-4">
                  <v-col cols="4"> 対象日* </v-col>
                  <v-col cols="8">
                    <DateTime label="対象日" v-model="editor.date" hideTime>
                      <template v-slot="{ click }">
                        <v-btn outlined v-bind="click.attrs" v-on="click.on"> {{ editor.date | date }} </v-btn>
                      </template>
                    </DateTime>
                  </v-col>
                </v-row>

                <template v-if="editor.merginType == MerginTypes.Sale">
                  <v-card class="my-2 mb-4" outlined>
                    <v-card-title>営業情報</v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="3">
                          <validation-provider v-slot="{ errors }" name="アポ・デモ" rules="required">
                            <v-select
                              v-model="editor.demoMerginType"
                              :items="[
                                { text: 'アポ', value: DemoMerginTypes.Apo },
                                { text: 'デモ', value: DemoMerginTypes.Demo },
                              ]"
                              label="*アポ・デモ"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                              @input="onChangeEditorRate"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="3">
                          <validation-provider v-slot="{ errors }" name="新規・リプレイス" rules="required">
                            <v-select
                              v-model="editor.demoContractType"
                              :items="[
                                { text: '新規', value: 1 },
                                { text: 'リプレイス', value: 1 << 1 },
                              ]"
                              label="*新規・新規・リプレイス"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                              @input="onChangeEditorRate"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="3">
                          受注日
                          <DateTime label="受注日" v-model="editor.saleDate" hideTime>
                            <template v-slot="{ click }">
                              <v-btn outlined v-bind="click.attrs" v-on="click.on"> {{ editor.saleDate | date }} </v-btn>
                            </template>
                          </DateTime>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field type="number" v-model.number="editor.sales" label="受注金額*" clearable outlined dense>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="editor.resultAmount"
                            label="売上金額*"
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="editor.residualBonds"
                            label="残債"
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="editor.underwritingBonds"
                            label="他社買取"
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model.number="editor.complimentaryBonds"
                            label="サービス"
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            v-model.number="editor.rate"
                            label="マージン率"
                            hint="例: 1% = 0.01と入力"
                            persistent-hint
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            v-model.number="editor.adjustment"
                            label="調整額"
                            clearable
                            outlined
                            dense
                            @input="onChangeEditor"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>

                <v-text-field type="number" v-model.number="editor.amount" label="報酬金額*" clearable outlined dense> </v-text-field>

                <v-select
                  v-model="editor.branch"
                  label="支店*"
                  :items="editor.branchs"
                  @change="editor.teams = editor.branch.teams"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-select
                  v-model="editor.team"
                  label="チーム*"
                  :items="editor.teams"
                  @change="editor.employees = editor.team.employees"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-select
                  v-model="editor.employee"
                  label="社員*"
                  :items="editor.employees"
                  item-value="code"
                  item-text="name"
                  return-object
                />
                <v-row>
                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" name="電話番号" rules="required|max:50">
                      <v-text-field
                        v-model="editor.facility.tel"
                        label="*電話番号"
                        :rules="[(v) => !!v || '電話番号 は必須です']"
                        hint="ハイフン抜きで入力してください"
                        counter
                        :maxlength="50"
                        :error-messages="errors"
                        clearable
                        required
                        dense
                        @input="onFacilityTelInputEditor"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="8">
                    <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
                      <v-select
                        v-model="editor.facility"
                        :items="editor.facilities"
                        item-text="name"
                        item-value="code"
                        label="*顧客名*"
                        :rules="[(v) => !!v || '顧客名 は必須です']"
                        :hint="`検索結果:${editor.facilities.length}件`"
                        :error-messages="errors"
                        persistent-hint
                        return-object
                        clearable
                        required
                        outlined
                        dense
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-textarea v-model="editor.memo" label="備考" clearable outlined dense> </v-textarea>
              </div>
            </template>
          </XLoader>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="warning" @click="onDeleteEditor"> 削除 </v-btn>

          <v-spacer />
          <v-btn text color="success" :disabled="!canCommitEditor()" @click="onCommitEditor(null)"> 仮登録 </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="demoEditor.show" persistent max-width="600" offset-y>
      <v-card>
        <v-card-title>
          <v-btn text color="warning" @click="demoEditor.show = false"> 閉じる </v-btn>
          <v-spacer />
          <v-btn color="primary" :disabled="!canCommitDemoEditor()" @click="onCommitDemoEditor(new Date())"> 業務承認 </v-btn>
        </v-card-title>
        <v-card-text>
          <XLoader :loading="demoEditor.loading">
            <template v-slot="{ loaded }">
              <div v-if="loaded">
                <v-row class="mb-4">
                  <v-col cols="3">
                    <div class="text-h6">売上金額</div>
                    <div>内訳</div>
                  </v-col>
                  <v-col cols="9">
                    <div class="text-h6">
                      =
                      {{
                        getAmountBase(
                          demoEditor.merginAmount,
                          demoEditor.residualBonds,
                          demoEditor.underwritingBonds,
                          demoEditor.complimentaryBonds
                        ) | priceJP
                      }}
                    </div>
                    <div>
                      <span> = {{ demoEditor.merginAmount | priceJP }}</span>
                      <span> - {{ demoEditor.residualBonds > 0 ? demoEditor.residualBonds : 0 | priceJP }} </span>
                      <span> - {{ demoEditor.underwritingBonds > 0 ? demoEditor.underwritingBonds : 0 | priceJP }} </span>
                      <span> - {{ demoEditor.complimentaryBonds > 0 ? demoEditor.complimentaryBonds : 0 | priceJP }}</span>
                    </div>
                    <div class="text-caption">(= 売上金額 - 残債 - 他社買取 - サービス)</div>

                    <!-- <div>
                      =
                      {{ (demoEditor.merginAmount - demoEditor.underwritingBonds - demoEditor.complimentaryBonds) | priceJP }}
                    </div>
                    <div>
                      =
                      {{ demoEditor.merginAmount | priceJP }}
                      -
                      {{ demoEditor.underwritingBonds | priceJP }}
                      -
                      {{ demoEditor.complimentaryBonds | priceJP }}
                    </div>
                    <div class="text-caption">= 売上金額 - 他社買取 - サービス</div> -->
                  </v-col>
                </v-row>

                <v-text-field
                  class="mb-4"
                  type="number"
                  v-model.number="demoEditor.rate"
                  label="率"
                  :hint="`調整前料率: ${demoEditor.defaultRate}`"
                  persistent-hint
                  clearable
                  outlined
                  dense
                  @input="
                    demoEditor.amount = getAmount(
                      demoEditor.merginAmount,
                      demoEditor.residualBonds,
                      demoEditor.underwritingBonds,
                      demoEditor.complimentaryBonds,
                      demoEditor.rate,
                      demoEditor.adjustment
                    )
                  "
                >
                </v-text-field>

                <v-row class="mb-4">
                  <v-col cols="3">
                    <div>調整前</div>
                  </v-col>
                  <v-col cols="9">
                    <div>
                      {{
                        getAmount(
                          demoEditor.merginAmount,
                          demoEditor.residualBonds,
                          demoEditor.underwritingBonds,
                          demoEditor.complimentaryBonds,
                          demoEditor.rate,
                          0
                        ) | priceJP
                      }}
                    </div>
                  </v-col>
                </v-row>

                <v-text-field
                  class="mb-4"
                  type="number"
                  v-model.number="demoEditor.adjustment"
                  label="調整額"
                  prefix="￥"
                  :hint="`マイナス値でマージン金額を減算できます`"
                  clearable
                  outlined
                  dense
                  @input="
                    demoEditor.amount = getAmount(
                      demoEditor.merginAmount,
                      demoEditor.residualBonds,
                      demoEditor.underwritingBonds,
                      demoEditor.complimentaryBonds,
                      demoEditor.rate,
                      demoEditor.adjustment
                    )
                  "
                >
                </v-text-field>

                <v-text-field
                  class="mb-4"
                  type="number"
                  v-model.number="demoEditor.amount"
                  label="マージン金額"
                  prefix="￥"
                  hint="読み取り専用です。マージン金額の変更は「率」「調整額」で行ってください"
                  persistent-hint
                  dense
                  readonly
                >
                </v-text-field>

                <v-textarea class="mt-4" v-model="demoEditor.memo" label="備考" clearable outlined dense> </v-textarea>
              </div>
            </template>
          </XLoader>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="success" :disabled="!canCommitDemoEditor()" @click="onCommitDemoEditor(null)"> 仮登録 </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>


<script>
export const DemoMerginTypes = {
  Demo: 1,
  Apo: 1 << 1,
};

export const MerginTypes = {
  Sukesan: 1,
  Rental: 1 << 1,
  Yadoda: 1 << 2,
  SP: 1 << 3,
  CancelPenalty: 1 << 4,
  Sale: 1 << 5,
};

export function GetDemoMerginTypeColor(type) {
  switch (type) {
    case DemoMerginTypes.Demo:
      return 'green lighten-4';
    case DemoMerginTypes.Apo:
      return 'lime lighten-4';
  }
  return null;
}
export function GetDemoMerginTypeLabel(type) {
  switch (type) {
    case DemoMerginTypes.Demo:
      return 'デモ';
    case DemoMerginTypes.Apo:
      return 'アポ';
  }
  return null;
}

export function GetMerginTypeColor(type) {
  switch (type) {
    case MerginTypes.Sukesan:
      return 'purple lighten-4';
    case MerginTypes.Rental:
      return 'teal lighten-4';
    case MerginTypes.Yadoda:
      return 'orange lighten-4';
    case MerginTypes.SP:
      return 'deep-orange lighten-4';
    case MerginTypes.CancelPenalty:
      return 'brown lighten-4';
  }
  return null;
}

export default {
  components: {},

  filters: {
    boolLabel(value) {
      return value ? '〇' : '';
    },
  },
  computed: {
    DemoMerginTypes() {
      return DemoMerginTypes;
    },
    MerginTypes() {
      return MerginTypes;
    },
    columnNames() {
      return [
        '対象期間', // 0
        '支店', // 1
        'チーム', // 2
        '物件', // 3
        '売上金額', // 4
        '残債', // 5
        '他社買取', // 6
        'サービス', // 7
        '受注区分', // 8
        '報酬対象金額', // 9
        '内訳', // 10
        '率', // 11
        '調整額', // 12
        '報酬金額', // 13
        '承認', // 14
      ];
    },

    term: {
      get() {
        if (this.date == null) return null;
        return this.$options.filters.libertyTerm(this.date);
      },
      set(newValue) {
        this.date = this.$options.filters.libertyTermToFirstDate(newValue);
      },
    },

    terms() {
      let date = this.$options.filters.getTermFirstDate(new Date());
      // 現在を含めた前３年
      return [...Array(3).keys()].map((a) => {
        let moment = this.$moment(date);
        let d = moment.add(-a, 'years').toDate();
        return {
          text: `${this.$options.filters.libertyTerm(d)}期 `,
          value: this.$options.filters.libertyTerm(d),
        };
      });
    },

    // 開始日(期首)
    dateStart() {
      // 期首日を取得
      return this.$options.filters.getTermFirstDate(this.date);
    },

    // 終了日(期末の5月の最終日)
    dateEnd() {
      // 期首日を取得
      let moment = this.$moment(this.$options.filters.getTermFirstDate(this.date));
      let year = moment.year();
      return new Date(year + 1, 6 - 1, 0);
    },

    datas() {
      let datas = this.merginDatas;

      // 開始
      if (this.filter.dateStart != null) {
        let date = this.$moment(this.filter.dateStart);
        datas = datas.filter((a) => this.$moment(a.date).isSameOrAfter(date));
      }
      // 終了
      if (this.filter.dateEnd != null) {
        let date = this.$moment(this.filter.dateEnd);
        datas = datas.filter((a) => this.$moment(a.date).isSameOrBefore(date));
      }

      // 支店
      if (this.filter.branchs.length > 0) {
        let codes = this.filter.branchs.map((a) => a.code);
        datas = datas.filter((a) => codes.includes(a.branch.code));
      }
      // チーム
      if (this.filter.teams.length > 0) {
        let codes = this.filter.teams.map((a) => a.code);
        datas = datas.filter((a) => codes.includes(a.team.code));
      }
      // 社員
      if (this.filter.employees.length > 0) {
        let codes = this.filter.employees.map((a) => a.code);
        datas = datas.filter((a) => {
          // // 関係者が指定した関係者以外を含んでいたらfalse
          // for (let employee of a.employee) {
          //   if (!codes.includes(employee.code)) return false;
          // }
          // return true;

          // 社員は「関係社員」として配列となっている filterで指定した社員が一つでも存在しているデータであればtrueとする
          for (let employee of a.employee) {
            if (codes.includes(employee.code)) return true;
          }
          return false;
        });
      }

      //
      return datas;
    },
  },

  data: () => ({
    initializing: false,

    tab: 0,
    date: null,

    fab: false,

    //

    branchs: [],
    teams: [],
    employees: [],

    // 表示中のカラム
    visibledColumns: [...Array(16).keys()],

    filter: {
      dateStart: null,
      dateEnd: null,
      branchs: [],
      teams: [],
      employees: [],
    },

    creator: {
      show: false,
      loading: false,
      demo: null,
      merginType: 1,
      demoMerginType: 1,
      demoContractType: 1,

      date: null,
      inputedDate: null,

      amount: null, // 最終的なマージン金額
      memo: null,

      rate: 0, // デモ(リース、現金)時のみ必要 それ以外は0でOK

      branch: null,
      team: null,
      employee: null,

      facilityTel: null,
      facility: null,

      // 営業情報
      saleDate: null,
      sales: null,
      resultAmount: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,
      adjustment: null,

      // セレクタ用
      branchs: [],
      teams: [],
      employees: [],
      facilities: [],
    },

    editor: {
      show: false,
      loading: false,
      merginCode: null,
      merginType: 1,

      date: null,
      inputedDate: null,

      amount: null, // 最終的なマージン金額
      memo: null,

      rate: 0, // デモ(リース、現金)時のみ必要 それ以外は0でOK

      branch: null,
      team: null,
      employee: null,

      facilityTel: null,
      facility: null,

      // 営業情報
      saleDate: null,
      sales: null,
      resultAmount: null,
      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,
      adjustment: null,

      // セレクタ用
      branchs: [],
      teams: [],
      employees: [],
      facilities: [],
    },

    demoEditor: {
      show: false,
      loading: false,
      demoCode: null,
      demoMerginType: 1,
      rate: 1, // デモ(リース、現金)時のみ必要
      residualBonds: 0, // デモ残債

      merginAmount: null, // 売上金額
      underwritingBonds: null, // 他社買取
      complimentaryBonds: null, // サービス品

      defaultRate: null, // 初期マージン率

      adjustment: 0, // 調整額

      amount: 0, // 最終的なマージン金額
      memo: null,

      defaultAmount: null, // 初期マージン率でのマージン金額
    },

    merginDatas: [],
  }),

  methods: {
    canCommitCreator() {
      if (this.creator.date == null) return false;
      if (this.creator.amount == null) return false;
      if (this.creator.branch == null) return false;
      if (this.creator.team == null) return false;
      if (this.creator.employee == null) return false;
      if (this.creator.facility == null) return false;
      return true;
    },
    canCommitEditor() {
      if (this.editor.date == null) return false;
      if (this.editor.amount == null) return false;
      if (this.editor.branch == null) return false;
      if (this.editor.team == null) return false;
      if (this.editor.employee == null) return false;
      if (this.editor.facility == null) return false;

      return true;
    },

    canCommitDemoEditor() {
      // 率も調整額もマージン金額も0の場合があるのでnullでなければよい
      if (!this.demoEditor.rate == null) return false;
      if (this.demoEditor.amount == null) return false;

      return true;
    },

    getMerginRate(demoMerginType, demoContractType) {
      //
      let isReplace = demoContractType == 1 << 1;

      // アポ時
      if (demoMerginType == DemoMerginTypes.Apo) {
        if (isReplace) return 0;
        return 0.04;
      }

      // デモ時
      if (demoMerginType == DemoMerginTypes.Demo) {
        if (isReplace) return 0.01;
        return 0.04;
      }

      return 0;
    },

    getAmountBase(merginAmount, residualBonds, underwritingBonds, complimentaryBonds) {
      let amount = merginAmount;
      //
      residualBonds = Number.parseInt(residualBonds);
      underwritingBonds = Number.parseInt(underwritingBonds);
      complimentaryBonds = Number.parseInt(complimentaryBonds);
      //
      if (!Number.isNaN(residualBonds)) amount -= residualBonds;
      if (!Number.isNaN(underwritingBonds)) amount -= underwritingBonds;
      if (!Number.isNaN(complimentaryBonds)) amount -= complimentaryBonds;
      return amount;
    },

    getAmount(merginAmount, residualBonds, underwritingBonds, complimentaryBonds, rate, adjustment) {
      let amount = this.getAmountBase(merginAmount, residualBonds, underwritingBonds, complimentaryBonds);
      /*
      let amount = merginAmount;
      // console.log('residualBonds', residualBonds);
      if (residualBonds != null) amount -= residualBonds;
      if (underwritingBonds != null) amount -= underwritingBonds;
      if (complimentaryBonds != null) amount -= complimentaryBonds;
      */

      //
      // let amount2 = (amount / 100) * rate * 100 + adjustment;
      let amount2 = amount * rate + adjustment;
      // 小数点一位で四捨五入
      amount2 = Math.round(amount2);
      //
      if (Number.isNaN(amount2)) amount2 = 0;

      return amount2;
    },
    getMergin(demoCode, demoMerginType) {
      let empty = {
        isEmpty: true,
        rate: null,
        adjustment: null,
        memo: null,
        amount: null,
        inputedDate: null,
      };

      let demo = this.datas
        .filter((a) => a.demo != null)
        .map((a) => a.demo)
        .find((a) => a.code == demoCode);
      if (demo == null) return empty;

      //
      //
      let merginDemo = demo.merginDemos.find((a) => a.demoMerginType == demoMerginType);
      if (merginDemo == null) return empty;

      return merginDemo.mergin;
    },
    isColumnVisibled(index) {
      return this.visibledColumns.includes(index);
    },

    async onLoaded() {
      this.initializing = true;

      // 全支店、チーム、社員を取得 プルダウンイベントでそれぞれに絞り込む
      {
        let success = await this.get('branches', {});
        let branchs = success.data;
        this.branchs = branchs;
        this.creator.branchs = branchs;
        this.editor.branchs = branchs;
      }
      {
        let success = await this.get('teams', {});
        let teams = success.data;

        this.teams = teams;
        this.creator.teams = teams;
        this.editor.teams = teams;
      }
      {
        let success = await this.get('employees', {});
        let employees = success.data;

        this.employees = employees;
        this.creator.employees = employees;
        this.editor.employees = employees;
      }

      // 初期表示時は前月を指定する
      if (this.date == null) {
        // this.date = this.$options.filters.getTermFirstDate(new Date());
        this.date = this.$moment().add(-1, 'months').toDate();
      }

      // 指定期間の営業ポイント情報取得
      {
        let dateStart = this.$moment(this.dateStart).format();
        let dateEnd = this.$moment(this.dateEnd).format();

        let success = await this.get('analyse/datas/mergin', { dateStart: dateStart, dateEnd: dateEnd });
        let data = success.data;
        this.merginDatas = data;

        // 初期タブは指定月
        let month = this.$moment(this.date).month() + 1;
        let tab = month + 6;
        if (month >= 6 && month <= 12) tab = month - 6;
        // 「全期間」のタブ分があるので+1
        this.tab = tab + 1;
        this.onMonthChanged(); // これをやらないとfilterが効かない(user/recordはタブ自体がfilter)

        // 初期化終了
        this.initializing = false;
      }
    },

    onBranchChanged() {
      this.teams = this.filter.branchs.flatMap((a) => a.teams);
    },
    onTeamChanged() {
      this.employees = this.filter.teams.flatMap((a) => a.employees);
    },
    onEmployeeChanged() {
      // computedでリアクティブに変更
    },
    onMonthChanged() {
      if (this.tab == 0) {
        // tab:0は全期間のためフィルタはリセット
        this.filter.dateStart = null;
        this.filter.dateEnd = null;
      } else {
        // 月の指定タブthis.tabは1から始まる

        let index = this.tab - 1;

        // 開始 : DB検索期間開始日＋指定タブIndex
        let start = this.$moment(this.dateStart);
        start = start.add(index, 'M').format();
        // 終わり : 指定月の次月-1日
        let end = this.$moment(start).add(1, 'M').add(-1, 'd');
        //
        this.filter.dateStart = start;
        this.filter.dateEnd = end.format();
      }
    },

    onTermChanged() {
      // リロードする
      this.onLoaded();
    },

    onShowCreator(merginType) {
      this.creator.merginType = merginType;
      //

      this.creator.demoMerginType = null;
      this.creator.demoContractType = null;
      this.creator.date = null;
      this.creator.inputedDate = null;
      this.creator.amount = 0;
      this.creator.rate = 0;
      this.creator.branch = null;
      this.creator.team = null;
      this.creator.employee = null;
      this.creator.facility = null;
      this.creator.facilityTel = null;
      this.creator.saleDate = null;
      this.creator.sales = null;
      this.creator.resultAmount = null;
      this.creator.residualBonds = null;
      this.creator.underwritingBonds = null;
      this.creator.complimentaryBonds = null;
      this.creator.adjustment = 0;

      //
      this.creator.show = true;
      this.creator.loading = false;
    },

    onShowEditor(merginCode) {
      this.get('mergin', { merginCode: merginCode }).then((success) => {
        var data = success.data;

        // this.editor.date = data.date;
        // this.editor.code = data.code;
        // this.editor.date = this.$moment(data.date).format();
        // this.editor.merginType = data.merginType;
        // this.editor.amount = data.amount;
        // this.editor.memo = data.memo;
        // this.editor.inputedDate = data.inputedDate;

        // this.editor.branch = data.branch;
        // this.editor.team = data.team;
        // this.editor.employee = data.employee;
        // this.editor.facility = data.facility;

        this.editor.code = data.code;
        this.editor.merginType = data.merginType;
        this.editor.demoMerginType = data.demoMerginType;
        this.editor.demoContractType = data.demoContractType;
        this.editor.date = this.$moment(data.date).format();
        this.editor.inputedDate = this.$moment(data.inputedDate).format();
        this.editor.amount = data.amount;
        this.editor.rate = data.rate;
        this.editor.branch = data.branch;
        this.editor.team = data.team;
        this.editor.employee = data.employee;
        this.editor.facility = data.facility;
        this.editor.facilityTel = data.facility.tel;
        this.editor.saleDate = this.$moment(data.saleDate).format();
        this.editor.sales = data.sales;
        this.editor.resultAmount = data.resultAmount;
        this.editor.residualBonds = data.residualBonds;
        this.editor.underwritingBonds = data.underwritingBonds;
        this.editor.complimentaryBonds = data.complimentaryBonds;
        this.editor.adjustment = data.adjustment;

        this.onFacilityTelInputEditor();

        this.editor.show = true;
        this.editor.loading = false;
      });
    },

    onShowDemoEditor(demoCode, demoMerginType, merginAmount, residualBonds, underwritingBonds, complimentaryBonds, defaultRate) {
      // 前の値を引きずるので初期化
      {
        this.demoEditor.demoCode = null;
        this.demoEditor.demoMerginType = 1;
        this.demoEditor.rate = 1;
        this.demoEditor.merginAmount = null;
        this.demoEditor.residualBonds = null;
        this.demoEditor.underwritingBonds = null;
        this.demoEditor.complimentaryBonds = null;
        this.demoEditor.defaultRate = null;
        this.demoEditor.adjustment = 0;
        this.demoEditor.amount = 0;
        this.demoEditor.memo = null;
        this.demoEditor.defaultAmount = null;
      }
      //
      this.get('mergin/demo', { demoCode: demoCode, demoMerginType: demoMerginType }).then((success) => {
        var data = success.data;

        this.demoEditor.demoCode = demoCode;
        this.demoEditor.demoMerginType = demoMerginType;
        this.demoEditor.merginAmount = merginAmount;
        this.demoEditor.residualBonds = residualBonds;
        this.demoEditor.underwritingBonds = underwritingBonds;
        this.demoEditor.complimentaryBonds = complimentaryBonds;
        this.demoEditor.defaultRate = defaultRate;

        //
        this.demoEditor.rate = defaultRate;

        if (data) {
          console.log('onShowDemoEditor data not empty');

          let mergin = data.mergin;
          this.demoEditor.rate = mergin.rate;
          this.demoEditor.adjustment = mergin.adjustment;
          this.demoEditor.memo = mergin.memo;
          //
          let inputedDate = mergin.inputedDate;

          if (inputedDate != null) {
            this.demoEditor.inputedDate = this.$moment(inputedDate).toDate();
          }
        }

        this.demoEditor.amount = this.getAmount(
          merginAmount,

          this.demoEditor.residualBonds,
          this.demoEditor.underwritingBonds,
          this.demoEditor.complimentaryBonds,
          this.demoEditor.rate,
          this.demoEditor.adjustment
        );

        // 万が一データとして登録された金額と自動計算の結果が違っていたらエラー
        if (data && data.mergin.amount != this.demoEditor.amount) {
          this.showError(
            `設定された報酬金額と登録された報酬金額が異なっていため修正されました。データを保存しこちらの値で登録しなおしてください. 正しい値:${this.demoEditor.amount} 現在の値:${data.mergin.amount} `
          );

          // throw new Error(`invalid data amount. except:${this.demoEditor.amount} actual:${data.mergin.amount} `);
        }

        // undefined対策
        // if (!this.demoEditor.rate) this.demoEditor.rate = 0;
        // if (!this.demoEditor.adjustment) this.demoEditor.adjustment = 0;

        this.demoEditor.show = true;
        this.demoEditor.loading = false;
      });
    },

    onChangeCreatorRate() {
      //
      this.creator.rate = this.getMerginRate(this.creator.demoMerginType, this.creator.demoContractType);
    },
    onChangeCreator() {
      this.creator.amount = this.getAmount(
        this.creator.resultAmount,
        this.creator.residualBonds,
        this.creator.underwritingBonds,
        this.creator.complimentaryBonds,
        this.creator.rate,
        this.creator.adjustment
      );
    },

    onCommitCreator(inputedDate) {
      //

      if (!confirm('登録してもよろしいですか？')) return;

      //
      this.creator.loading = true;

      let request = this.creator;

      // 宿情報が手入力
      if (this.isString(request.facility)) {
        request.facility = {
          name: request.facility,
          tel: request.facilityTel,
        };
      }
      // date
      request.date = this.convertDate(request.date);
      request.saleDate = this.convertDate(request.saleDate);
      if (inputedDate != null) {
        request.inputedDate = this.convertDate(inputedDate);
      } else {
        request.inputedDate = null;
      }

      // nullable
      request.rate = this.convertNumber(request.rate);
      request.adjustment = this.convertNumber(request.adjustment);
      request.amount = this.convertNumber(request.amount);

      request.sales = this.convertNumber(request.sales);
      request.resultAmount = this.convertNumber(request.resultAmount);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      this.post('mergin', request)
        .then(() => {
          // pushなどは面倒なのでシンプルにリロード
          this.onLoaded();

          this.creator.show = false;
        })
        .finally(() => {
          this.creator.loading = false;
        });
    },

    onChangeEditorRate() {
      //
      this.editor.rate = this.getMerginRate(this.editor.demoMerginType, this.editor.demoContractType);
    },
    onChangeEditor() {
      this.editor.amount = this.getAmount(
        this.editor.resultAmount,
        this.editor.residualBonds,
        this.editor.underwritingBonds,
        this.editor.complimentaryBonds,
        this.editor.rate,
        this.editor.adjustment
      );
    },

    onCommitEditor(inputedDate) {
      //
      if (!confirm('登録してもよろしいですか？')) return;
      //

      this.editor.loading = true;

      let request = this.editor;
      // date
      request.date = this.convertDate(request.date);
      if (inputedDate != null) {
        request.inputedDate = this.convertDate(inputedDate);
      } else {
        request.inputedDate = null;
      }
      // nullable
      request.rate = this.convertNumber(request.rate);
      request.adjustment = this.convertNumber(request.adjustment);
      request.amount = this.convertNumber(request.amount);

      request.sales = this.convertNumber(request.sales);
      request.resultAmount = this.convertNumber(request.resultAmount);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);

      this.post('mergin', request)
        .then(() => {
          // pushなどは面倒なのでシンプルにリロード
          this.onLoaded();

          this.editor.show = false;
        })
        .finally(() => {
          this.editor.loading = false;
        });
    },

    onDeleteEditor() {
      //
      if (!confirm('削除してもよろしいですか？')) return;

      this.editor.loading = true;

      let request = this.editor;

      this.delete('mergin', request).then(() => {
        // pushなどは面倒なのでシンプルにリロード
        this.onLoaded();

        this.editor.loading = false;
        this.editor.show = false;
      });
    },

    onCommitDemoEditor(inputedDate) {
      //
      if (!confirm('登録してもよろしいですか？')) return;

      this.demoEditor.loading = true;

      let request = this.demoEditor;
      // date
      if (inputedDate != null) {
        request.inputedDate = this.convertDate(inputedDate);
      } else {
        request.inputedDate = null;
      }
      // nullable
      request.adjustment = this.convertNumber(request.adjustment);

      this.post('mergin', request)
        .then((success) => {
          var data = success.data;
          //
          let merginCode = data.code;

          // デモデータのマージンデータを更新する(なければ追加)
          // 対象のdemoデータ取得（これは必ずある）
          let demo = this.datas.map((a) => a.demo).find((a) => a.code == request.demoCode);

          let merginDemo = demo.merginDemos.find((a) => a.mergin.code == merginCode);
          if (merginDemo == null) {
            demo.merginDemos.push({
              demoMerginType: request.demoMerginType,
              mergin: data,
            });
          } else {
            merginDemo.mergin.rate = request.rate;
            merginDemo.mergin.adjustment = request.adjustment;
            merginDemo.mergin.memo = request.memo;
            merginDemo.mergin.inputedDate = request.inputedDate;
          }

          this.demoEditor.loading = false;
          this.demoEditor.show = false;

          // pushなどは面倒なのでシンプルにリロード
          this.onLoaded();
        })
        .catch((ex) => {
          console.log('catch', ex);

          this.showError('データ登録に失敗しました');
        });
    },

    onFacilityTelInput() {
      let facilityTel = this.creator.facilityTel;
      if (facilityTel != null && facilityTel.length <= 2) return;

      this.get('search/facility/tel', { tel: facilityTel }).then((success) => {
        let data = success.data;
        this.creator.facilities = data;
      });
    },

    onFacilityTelInputEditor() {
      let facilityTel = this.editor.facility.tel;
      if (facilityTel != null && facilityTel.length <= 2) return;

      this.get('search/facility/tel', { tel: facilityTel }).then((success) => {
        let data = success.data;
        this.editor.facilities = data;
      });
    },

    getDatas(moment) {
      let datas = this.datas;

      if (moment != null) {
        let year = moment.year();
        let month = moment.month() + 1;
        datas = datas.filter((a) => a.year == year).filter((a) => a.month == month);
      }
      //
      let demos = datas.flatMap((a) => a.demoPoints);

      return {
        demos: demos,
      };
    },

    getModifiedColor(isModified) {
      return isModified ? 'yellow lighten-4' : 'transparent';
    },

    getDemoMerginTypeColor(type) {
      return GetDemoMerginTypeColor(type);
    },

    getDemoMerginTypeLabel(type) {
      return GetDemoMerginTypeLabel(type);
    },

    getMerginTypeColor(type) {
      return GetMerginTypeColor(type);
    },

    getMerginFormulaLabel(merginAmount, underwritingBonds, complimentaryBonds, rate, adjustment) {
      let label = `(売上金額 - 他社買取 - サービス) x 率 + 調整額`;
      label += `<br />`;
      label += ` = `;
      label += `(`;
      label += this.$options.filters.priceJP(merginAmount ? merginAmount : 0);
      label += ` - `;
      label += this.$options.filters.priceJP(underwritingBonds ? underwritingBonds : 0);
      label += ` - `;
      label += this.$options.filters.priceJP(complimentaryBonds ? complimentaryBonds : 0);
      label += `)`;
      label += ` x `;
      label += rate;
      label += `% `;

      label += ` + `;
      label += this.$options.filters.priceJP(adjustment ? adjustment : 0);

      return label;
    },

    // 検索結果で表示させてよいアポインターか？
    canDispApointer(data) {
      if (this.filter.employees.length == 0) return true;
      if (data.demo.invalidApointer) return;

      //
      let employeeCode = null;
      if (data.demo.apo != null && data.demo.apo.apointer != null) employeeCode = data.demo.apo.apointer.code;
      else if (data.demo.apointer != null) employeeCode = data.demo.apointer.code;
      else if (data.demo.apo != null && data.demo.apo.branch != null) return true;

      return this.filter.employees.map((a) => a.code).includes(employeeCode);
    },
    // 検索結果で表示させてよいデモ担当者か
    canDispDemointer(data) {
      if (this.filter.employees.length == 0) return true;
      //
      let employeeCode = data.demo.demointer.code;
      return this.filter.employees.map((a) => a.code).includes(employeeCode);
    },
  },
  watch: {},
  async mounted() {
    await this.onLoaded();
  },
};
</script>

<style scoped>
/* .v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
} */
</style>